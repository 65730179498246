<template>
  <section>
    <b-row class="mt-2 mb-1">
      <b-col>
        <div class="d-flex justify-content-between align-items-center">
          <h4>Coupons</h4>
          <b-row class="w-75 flex align-items-center">
            <b-col md="1" class="pr-0 text-right">Period</b-col>
            <b-col md="6">
              <div class="d-flex custom-date w-100" v-if="date === 'custom'">
                <v-select v-model="date" :options="dateOptions" :reduce="(val) => val.value" class="w-50" />
                <flat-pickr v-model="customDate" class="form-control w-60 px-1" :config="{ mode: 'range' }" placeholder="Select Date" />
              </div>
              <v-select v-else v-model="date" :options="dateOptions" :reduce="(val) => val.value" />
            </b-col>
            <b-col md="5" class="pr-0">
              compared with {{ $moment.unix(previous_from_date).format('YYYY-MM-DD') }} to {{ $moment.unix(previous_to_date).format('YYYY-MM-DD') }}
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="match-height" v-if="stats">
      <b-col lg="4" sm="6">
        <stats-card title="Total Coupons" :users="stats.users" :stat="stats.total_coupons" :previous_stat="previous_stats.total_coupons"></stats-card>
      </b-col>
      <b-col lg="4" sm="6">
        <stats-card
          title="Active Coupons"
          :users="stats.users"
          :stat="stats.total_active_coupons"
          :previous_stat="previous_stats.total_active_coupons"
        ></stats-card>
      </b-col>
      <b-col lg="4" sm="6">
        <stats-card title="Used Coupons" :users="stats.users" :stat="stats.total_used_coupons" :previous_stat="previous_stats.total_used_coupons"></stats-card>
      </b-col>
      <b-col lg="4" sm="6">
        <stats-card
          title="Amount Coupons"
          :users="stats.users"
          :stat="stats.total_amount_coupons"
          :previous_stat="previous_stats.total_amount_coupons"
        ></stats-card>
      </b-col>
      <b-col lg="4" sm="6">
        <stats-card
          title="Percentage Coupons"
          :users="stats.users"
          :stat="stats.total_percentage_coupons"
          :previous_stat="previous_stats.total_percentage_coupons"
        ></stats-card>
      </b-col>
      <b-col lg="4" sm="6">
        <stats-card
          title="Individual Activity Coupons"
          :users="stats.users"
          :stat="stats.total_individual_activities_coupons"
          :previous_stat="previous_stats.total_individual_activities_coupons"
        ></stats-card>
      </b-col>
      <b-col lg="4" sm="6">
        <stats-card
          title="All Activity Coupons"
          :users="stats.users"
          :stat="stats.total_all_activities_coupons"
          :previous_stat="previous_stats.total_all_activities_coupons"
        ></stats-card>
      </b-col>
      <b-col lg="4" sm="6">
        <stats-card
          title="Average Coupon Validity"
          sub_text="Days"
          :stat="stats.average_coupon_validity"
          :previous_stat="previous_stats.average_coupon_validity"
        ></stats-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import StatsCard from '../Components/StatsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    flatPickr,
    StatsCard,
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      previous_from_date: null,
      previous_to_date: null,
      date: null,
      dateOptions: [
        { label: 'Last 7 Days', value: 'lastseven' },
        { label: 'Last 30 Days', value: 'lastthirty' },
        { label: 'This Month', value: 'thismonth' },
        { label: 'Last Month', value: 'lastmonth' },
        { label: 'This Year', value: 'thisyear' },
        { label: 'Last 365 Days', value: 'last365days' },
        { label: 'All Time', value: 'all' },
        { label: 'Custom', value: 'custom' },
      ],
      customDate: null,
      stats: [],
      previous_stats: [],
    }
  },
  watch: {
    date: function (newDate, oldDate) {
      if (newDate !== 'custom') {
        if (newDate === 'lastseven') {
          this.from_date = this.$moment().subtract(7, 'd').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'lastthirty') {
          this.from_date = this.$moment().subtract(30, 'd').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'thismonth') {
          this.from_date = this.$moment().startOf('month').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'lastmonth') {
          this.from_date = this.$moment().startOf('month').subtract(1, 'M').unix()
          this.to_date = this.$moment().endOf('month').subtract(1, 'M').unix()
        } else if (newDate === 'thisyear') {
          this.from_date = this.$moment().startOf('year').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'last365days') {
          this.from_date = this.$moment().subtract(365, 'd').unix()
          this.to_date = this.$moment().unix()
        } else if (newDate === 'all') {
          this.from_date = 0
          this.to_date = this.$moment().unix()
        }
        this.calculatePreviousFromTo()
        this.fetch()
      }
    },
    customDate: function (newDate, oldDate) {
      const [from, to] = newDate.split(' to ')
      if (from && to) {
        this.from_date = this.$moment(from).unix()
        this.to_date = this.$moment(to).unix()
        this.calculatePreviousFromTo()
        this.fetch()
      }
    },
  },
  mounted() {
    this.from_date = this.$moment().subtract(60, 'd').unix()
    this.to_date = this.$moment().unix()
    this.date = 'lastseven'
  },
  methods: {
    calculatePreviousFromTo() {
      this.previous_to_date = this.from_date - 86400
      this.previous_from_date = this.previous_to_date - (this.to_date - this.from_date)
    },
    fetch() {
      this.stats = []
      this.$http.get(`/analytics/coupons/${this.from_date}/${this.to_date}`).then((res) => {
        this.stats = res.data
      })
      this.previous_stats = []
      this.$http.get(`/analytics/coupons/${this.previous_from_date}/${this.previous_to_date}`).then((res) => {
        this.previous_stats = res.data
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.custom-date {
  [role='combobox'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  input[type='text'] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}
.w-60 {
  width: 60%;
}
</style>

<template>
  <section>
    <activities />
    <bookings />
    <coupons />
    <others />
  </section>
</template>

<script>
import Activities from './Activities.vue'
import Bookings from './Bookings.vue'
import Coupons from './Coupons.vue'
import Others from './Others.vue'

export default {
  components: {
    Activities,
    Bookings,
    Coupons,
    Others,
  },
}
</script>

<style></style>
